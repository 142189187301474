    @font-face {
    font-family: 'Silka'; /* Use the actual font name here */
    src: url('./silka-light-webfont.ttf') format('ttf'),
         url('./silka-light-webfont.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Silka';
    src: url('./silka-black-webfont.ttf') format('ttf'),
         url('./silka-black-webfont.ttf') format('ttf');
    font-weight: bold;
    font-style: normal;
  }